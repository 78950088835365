import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ICategory } from "@/models/ICategory";
import CategoryService from "@/services/CategoryService";
import { namespace } from "vuex-class";
import { Watch } from "vue-property-decorator";

const Auth = namespace("Auth");

@Options({
  components: {
    AdminLayout,
    ValidationObserver,
  },
})
export default class WebPageModule extends Vue {
  active = false;
  loading = false;
  isloaded = false;
  userId = 0;
  categories: any = [];
  totalItems = 0;
  current_page = 1;
  search: string = "";
  pageHeading: string = "";
  acceptAlert = false;
  delCatId = 0;
  activePrompt = false;

  refForm: any = (this as any).$refs.form;
  isFailed = false;
  failedMessage = "";

  $refs!: {
    validationProviderRef: InstanceType<typeof ValidationProvider>;
    validationObserverRef: InstanceType<typeof ValidationObserver>;
  };

  table = {
    direction: "DESC",
    orderBy: "id",
    page: 0,
    size: 3,
    totalElements: 0,
  };

  category: ICategory = {
    category1: "",
    categoryid: 0,
  };

  @Auth.Getter
  private getBranchId!: string;

  async created() {
    (this as any).$Progress.start();
    var result = await CategoryService.getListofCategory(this.getBranchId);
    this.categories = result;
    this.isloaded = true;
    (this as any).$Progress.finish();
  }

  async setTable(pagenumber: number) {
    (this as any).$Progress.start();
    this.categories = [];
    var result = await CategoryService.getListofCategory(this.getBranchId);
    this.isloaded = false
    this.categories = result;
    this.isloaded = true;
    (this as any).$Progress.finish();
  }

  onBack() {
    //this.setTable(1)
    this.active = !this.active;
  }

  async onEdit(pageId: number) {
    (this as any).$Progress.start();
    this.category = await CategoryService.getCategoryById(
      pageId,
      this.getBranchId
    );
    this.pageHeading = "Update Category";
    this.active = !this.active;
    (this as any).$Progress.finish();
  }

  onOpen() {
    this.pageHeading = "Add New Category";
    this.active = !this.active;
    this.category = {
      categoryid: 0,
      category1: "",
    };
  }

  openConfirm(catId: number) {
    // (this as any).$vs.dialog({
    //   type: "confirm",
    //   color: "danger",
    //   title: `Confirm`,
    //   text: "Are you sure you want to delete category?",
    //   accept: this.deleteCategory(catId),
    //   acceptText: "Yes",
    //   cancelText: "No",
    // });
    this.delCatId = catId;
    this.activePrompt = true;
  }
  @Watch("getBranchId")
  onGetBranchIdChanged(value: string, oldValue: string) {
    this.setTable(1)
  }
  deleteCategory(catId: number) {
    (this as any).$Progress.start();
    console.log(catId);
    CategoryService.deleteCategory(this.delCatId, this.getBranchId).then(
      (data) => {
        this.loading = false;
        if (data.statusCode == 200) {
          this.setTable(1);
          (this as any).$vs.notify({
            title: "Blog Category",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
        } else {
          this.isFailed = true;
          this.failedMessage = data.message;
          (this as any).$vs.notify({
            title: "Blog Category",
            text: data.message,
            color: "danger",
            icon: " highlight_off",
            time: 6000,
          });
          (this as any).$Progress.finish();
        }
        (this as any).$refs.observer.reset();
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "Blog Category",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }

  async onSubmit() {
    (this as any).$Progress.start();
    this.loading = true;

    CategoryService.saveCategory(this.category, this.getBranchId).then(
      (data) => {
        this.loading = false;
        if (data.statusCode == 200) {
          this.setTable(1);
          (this as any).$vs.notify({
            title: "Web Page",
            text: data.message,
            color: "success",
            icon: "check_circle",
          });
          (this as any).$Progress.finish();
          this.category = {
            category1: "",
            categoryid: 0,
          };
        } else {
          this.isFailed = true;
          this.failedMessage = data.message;
          (this as any).$vs.notify({
            title: "Web Page",
            text: data.message,
            color: "danger",
            icon: " highlight_off",
            time: 6000,
          });
        }
        (this as any).$refs.observer.reset();
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "Web Page",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );
  }
}
